// Id нельзя менять нигде
export const categories = [
  {
    id: 1,
    title: 'Foodtech',
    slug: 'foodtech',
    showInPortfolio: true,
    showInIndustries: false,
    metaTitle: 'IT Solutions for Foodtech | Web and Mobile development',
    metaDescription:
      'Take a look at a selection of our Foodtech portfolio examples we have worked on in recent times. From software design to IT development, our case studies showcase our expertise and commitment to delivering exceptional results.',
    iconName: null,
    isComingSoon: false,
    description: null,
  },
  {
    id: 5,
    title: 'eCommerce',
    slug: 'e-commerce',
    showInPortfolio: true,
    showInIndustries: true,
    metaTitle: 'IT Solutions for Ecommerce | Web and Mobile development',
    metaDescription:
      'Take a look at a selection of our e-commerce portfolio examples we have worked on in recent times. From software design to IT development, our case studies showcase our expertise and commitment to delivering exceptional results.',
    iconName: 'sprite/e-commerce',
    isComingSoon: false,
    description:
      'Create your own marketplace or online store to attract new audiences and increase sales.',
  },
  {
    id: 30,
    title: 'Automation',
    slug: 'automation',
    showInPortfolio: true,
    showInIndustries: true,
    metaTitle: 'IT Solutions for Automation | Web and Mobile development',
    metaDescription:
      'Take a look at a selection of our Automation portfolio examples we have worked on in recent times. From software design to IT development, our case studies showcase our expertise and commitment to delivering exceptional results.',
    iconName: 'sprite/automation',
    isComingSoon: false,
    description: 'Improve performance by automating routine tasks and processes.',
  },
  {
    id: 45,
    title: 'MCN',
    slug: 'msn',
    showInPortfolio: true,
    showInIndustries: true,
    metaTitle: 'IT Solutions for MCN | Web and Mobile development',
    metaDescription:
      'Take a look at a selection of our MCN portfolio examples we have worked on in recent times. From software design to IT development, our case studies showcase our expertise and commitment to delivering exceptional results.',
    iconName: 'sprite/mcn',
    isComingSoon: false,
    description:
      'Partner with major video platforms with your own full-cycle collaboration solution.',
  },
  {
    id: 10,
    title: 'Travel and hospitality',
    slug: 'travel_and_hospitality',
    showInPortfolio: true,
    showInIndustries: true,
    metaTitle: 'IT Solutions for Travel and hospitality | Web and Mobile development',
    metaDescription:
      'Take a look at a selection of our Travel and hospitality portfolio examples we have worked on in recent times. From software design to IT development, our case studies showcase our expertise and commitment to delivering exceptional results.',
    iconName: 'sprite/travel-hospitality',
    isComingSoon: false,
    description:
      'Find your customers online and bring them into your space by providing excellent service wherever they go.',
  },
  {
    id: 15,
    title: 'Fintech',
    slug: 'fintech',
    showInPortfolio: false,
    showInIndustries: false,
    metaTitle: null,
    metaDescription: null,
    iconName: null,
    isComingSoon: false,
    description: null,
  },
  {
    id: 20,
    title: 'Telecom',
    slug: 'telecom',
    showInPortfolio: false,
    showInIndustries: false,
    metaTitle: null,
    metaDescription: null,
    iconName: null,
    isComingSoon: false,
    description: null,
  },
  {
    id: 25,
    title: 'Real Estate',
    slug: 'real_estate',
    showInPortfolio: false,
    showInIndustries: false,
    metaTitle: null,
    metaDescription: null,
    iconName: null,
    isComingSoon: false,
    description: null,
  },

  {
    id: 40,
    title: 'E-learning',
    slug: 'e_learning',
    showInPortfolio: false,
    showInIndustries: false,
    metaTitle: null,
    iconName: null,
    isComingSoon: false,
    description: null,
  },

  {
    id: 50,
    title: 'Crowdfunding',
    slug: 'crowdfunding',
    showInPortfolio: false,
    showInIndustries: true,
    metaTitle: 'IT Solutions for Crowdfunding | Web and Mobile development',
    metaDescription:
      'Take a look at a selection of our Crowdfunding portfolio examples we have worked on in recent times. From software design to IT development, our case studies showcase our expertise and commitment to delivering exceptional results.',
    iconName: 'sprite/crowdfunding',
    isComingSoon: false,
    description:
      'Build a fundraising platform with integrated payments, multi-role management and landing templates.',
  },
  {
    id: 55,
    title: 'Blockchain',
    slug: 'blockchain',
    showInPortfolio: false,
    showInIndustries: true,
    metaTitle: 'IT Solutions for Blockchain | Web and Mobile development',
    metaDescription:
      'Take a look at a selection of our Blockchain portfolio examples we have worked on in recent times. From software design to IT development, our case studies showcase our expertise and commitment to delivering exceptional results.',
    iconName: 'sprite/blockchain',
    isComingSoon: false,
    description:
      'Get a secure communication channel for device synchronisation and data transfer without server storage.',
  },
  {
    id: 60,
    title: 'Wellness',
    slug: 'wellness',
    showInPortfolio: true,
    showInIndustries: false,
    metaTitle: 'Software & App Development for Wellness | dev.family',
    metaDescription: `Take a look at our case studies highlighting successful projects in the Wellness. These examples demonstrate our software development company's expertise and commitment to delivering exceptional results.`,
    iconName: null,
    isComingSoon: false,
    description: null,
  },
  {
    id: 65,
    title: 'Telegram',
    slug: 'telegram',
    showInPortfolio: false,
    showInIndustries: false,
    metaTitle: 'Telegram Software & App Development Cases | dev.family',
    metaDescription: `A collection of Telegram software and app development cases showcasing scalable and efficient solutions for various industries`,
    iconName: null,
    isComingSoon: false,
    description: null,
  },
  {
    id: 70,
    title: 'Others',
    slug: 'others',
    showInPortfolio: true,
    showInIndustries: false,
    metaTitle: 'Other Projects | Software & App Development | dev.family',
    metaDescription:
      'View a range of software and app development projects, highlighting unique solutions and successful outcomes across various industries',
    iconName: 'sprite/blockchain',
    isComingSoon: false,
    description: `We never limit ourselves to working only with certain industries. Here is a collection of cases that are difficult to group together, but of which we are very proud.`,
  },
  // 70 - последний category id
]

// 64 - последний case id
// images - тут сейчас досаточно одного изображения, это всё пережитки прошлого, я это уберу позже, просто всем ключам ['380x380'], ['420x420'] можешь задать одно и то же изображение
export const cases = [
  {
    id: 62,
    slug: 'openorigin',
    showInPortfolio: true,
    category: categories.find((i) => i.slug === 'automation'),
    title: 'OpenOrigin. Web platform for AI models',
    description:
      'MVP of a decentralised platform for AI enthusiasts to showcase their models and get user feedback',
    images: {
      ['380x380']: require('../images/openorigin-900x900@2x.png').default?.src,
      ['420x420']: require('../images/openorigin-900x900@2x.png').default?.src,
      ['728x728']: require('../images/openorigin-900x900@2x.png').default?.src,
    },

    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 61,
    slug: 'foodclick',
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 1),
    title: 'Mobile app for tables reservations and pre-orders',
    description:
      'Restaurant aggregator app with table booking software, pre-orders and cashless tips',
    images: {
      ['380x380']: require('../images/foodclick-900x900@2x.png').default?.src,
      ['420x420']: require('../images/foodclick-900x900@2x.png').default?.src,
      ['728x728']: require('../images/foodclick-900x900@2x.png').default?.src,
    },

    links: {
      site: null,
      appIos:
        'https://apps.apple.com/us/app/%D1%84%D1%83%D0%B4%D0%BA%D0%BB%D0%B8%D0%BA/id1512023870',
      appAndroid: 'https://play.google.com/store/apps/details?id=com.ypainfood.appclient',
    },
  },
  {
    id: 60,
    slug: 'yapoki',
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 1),
    title: 'Yapoki. Fast-growing food delivery app',
    description:
      'We developed a delivery app for a fast-growing restaurant chain with a large, complex architecture and bespoke functionality.',
    images: {
      //   ['308x308']: require('../../../assets/images/zubr/zubr-og-sq.jpg'),
      ['380x380']: require('../images/yapoki-900x900@2x.png').default?.src,
      ['420x420']: require('../images/yapoki-900x900@2x.png').default?.src,
      ['728x728']: require('../images/yapoki-900x900@2x.png').default?.src,
    },

    links: {
      site: null,
      appIos: 'https://apps.apple.com/ru/app/%D1%8F%D0%BF%D0%BE%D0%BA%D0%B8/id6473904226',
      appAndroid: 'https://play.google.com/store/apps/details?id=com.yapoki&hl=ru&gl=US',
    },
  },
  {
    id: 2,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 30),
    title: 'Polymer. Personal account for B2B clients',
    description:
      'We helped digitize large businesses, reduce the labor hours on working with customers and improve the service',
    images: {
      //   ['308x308']: '/images/portfolio/polimer-sq.png',
      ['380x380']: require('../images/polymer-420x420@2x.jpg').default?.src,
      ['420x420']: require('../images/polymer-420x420@2x.jpg').default?.src,
      ['728x728']: require('../images/polymer-728x728@2x.jpg').default?.src,
    },
    slug: 'polymertorg',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 64,
    slug: 'travelgo',
    showInPortfolio: true,
    category: categories.find((i) => i.slug === 'travel_and_hospitality'),
    title: 'Mobile app and website for travel booking',
    description:
      'Travelgo. Travel app and website with real-time updates and flexible booking options',
    images: {
      ['380x380']: require('../images/travelgo-900x900@2x.png').default?.src,
      ['420x420']: require('../images/travelgo-900x900@2x.png').default?.src,
      ['728x728']: require('../images/travelgo-900x900@2x.png').default?.src,
    },

    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 63,
    slug: 'nined',
    showInPortfolio: true,
    category: categories.find((i) => i.slug === 'wellness'),
    title: '9D. Wellness mobile app',
    description: 'Handy tool to organise meditations and breathing exercises.',
    images: {
      ['380x380']: require('../images/nined-900x900@2x.png').default?.src,
      ['420x420']: require('../images/nined-900x900@2x.png').default?.src,
      ['728x728']: require('../images/nined-900x900@2x.png').default?.src,
    },

    links: {
      site: null,
      appIos: 'https://apps.apple.com/pt/app/9d-breathwork/id6680193387',
      appAndroid:
        'https://play.google.com/store/apps/details?id=com.breathwork.breathwork9D&hl=ru&pli=1',
    },
  },
  {
    id: 13,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 1),
    title: 'Bushe. Pastry ordering platform',
    description: "We've created a web platform for ordering pastry products for delivery or pickup",
    images: {
      //   ['308x308']: '/images/portfolio/bushe_prev@2x.png',
      ['380x380']: require('../images/bushe-420x420@2x.jpg').default?.src,
      ['420x420']: require('../images/bushe-420x420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'bushe',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 5,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 5),
    title: 'Fashion House. Website and mobile app for a premium retailer',
    description:
      'How we created an e-commerce solution from scratch with a lot of potential for the future',
    images: {
      //   ['308x308']: require('../../../assets/images/fh/fh-308x308@2x.png'),
      ['380x380']: require('../images/fh-420x420@2x.jpg').default?.src,
      ['420x420']: require('../images/fh-420x420@2x.jpg').default?.src,
      ['728x728']: require('../images/fh-728x728@2x.jpg').default?.src,
    },
    slug: 'fh',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 37,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 5),
    title: 'RMarket. Installment based marketplace for electronics & appliances',
    description:
      'How we developed a marketplace that aggregates thousands of  suppliers and provides convinient installment deals for customers',
    images: {
      //   ['308x308']: require('../../../assets/images/rassr/rmarket-308x308@2x.png'),
      ['380x380']: require('../images/rmarket-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/rmarket-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'rmarket',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 6,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 45),
    title: 'Mediacube. CRM-platform for the official YouTube partner network',
    description:
      'How we created a web tool that provides access to statistical data to influencers and automates financial interaction with them',
    images: {
      //   ['308x308']: '/images/portfolio/mediacube-sq-mobile.png',
      ['380x380']: null,
      ['420x420']: require('../images/mediacube-420-420@2x.jpg').default?.src,
      ['728x728']: require('../images/mediacube-728x728@2x.jpg').default?.src,
    },
    slug: 'mediacube',
    links: {
      site: 'https://mediacube.io',
      appIos: null,
      appAndroid: null,
    },
  },

  {
    id: 7,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 30),
    title: 'Reso. Website & personal account for automobile leasing company',
    description:
      'How we refined existing website and personal account to improve and expand functionality',
    images: {
      //   ['308x308']: '/images/portfolio/reso_sq_mobile@2x.png',
      ['380x380']: require('../images/reso-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/reso-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'reso',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 8,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 1),
    title: 'Beerpoint. Mobile app for beverage and snacks saler',
    description:
      'How we transformed and digitalized offline beer industry business and created a loyalty program mobile app',
    images: {
      //   ['308x308']: '/images/portfolio/beerpoint_app_sq_mobile@2x.png',
      ['380x380']: require('../images/beerpoint-420x420@2x.jpg').default?.src,
      ['420x420']: require('../images/beerpoint-420x420@2x.jpg').default?.src,
      ['728x728']: require('../images/beerpoint-728x728@2x.jpg').default?.src,
    },
    slug: 'beerpoint',
    links: {
      site: null,
      appIos: `https://apps.apple.com/us/app/пивточка/id1562572149`,
      appAndroid: `https://play.google.com/store/apps/details?id=com.beerpoint`,
    },
  },
  {
    id: 9,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 30),
    title: 'Mobile app & personal account for a BMW dealer',
    description:
      'How we made a full-fledged business class remote service with a good set of marketing tools',
    images: {
      //   ['308x308']: '/images/portfolio/bmw-sq.png',
      ['380x380']: require('../images/bmw-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/bmw-420-420@2x.jpg').default?.src,
      ['728x728']: require('../images/bmw-728x728@2x.jpg').default?.src,
    },
    slug: 'bayencraft',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 10,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 1),
    title: 'John Dory. Loyalty program mobile app',
    description:
      'How we made a unified loyalty program for all retail chain stores in a single mobile app',
    images: {
      //   ['308x308']: require('../../../public/images/main/johndory-eng.png'),
      ['380x380']: require('../images/johndorry-420-420@2x.png').default?.src,
      ['420x420']: require('../images/johndorry-420-420@2x.png').default?.src,
      ['728x728']: require('../images/johndorry-420-420@2x.png').default?.src,
    },
    slug: 'johndory',
    links: {
      site: null,
      appIos: 'https://apps.apple.com/ru/app/john-dory/id1591614825',
      appAndroid: 'https://play.google.com/store/apps/details?id=com.johndory&pli=1',
    },
  },
  {
    id: 11,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 1),
    title: 'Druzya. Website for a restaurant with the table reservation option',
    description:
      'How we automated workflows related to table reservations and kitchen load control',
    images: {
      //   ['308x308']: '/images/portfolio/druzya_sq.png',
      ['380x380']: require('../images/druzya-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/druzya-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'druzya',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 12,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 10),
    title: 'Ski4u. Winter Holiday booking platform',
    description:
      'How we implemented hotel and transfer booking service in Andorra, Austria and Italy',
    images: {
      //   ['308x308']: '/images/portfolio/ski4u_prev@2x.png',
      ['380x380']: require('../images/ski4you-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/ski4you-420-420@2x.jpg').default?.src,
      ['728x728']: require('../images/ski4u-728x728@2x.jpg').default?.src,
    },
    slug: 'ski4u',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 14,
    showInPortfolio: true,
    category: categories.find(({ slug }) => slug === 'wellness'),
    title: 'Yoga Stacks. Simple yoga studying app for yoga enthusiasts',
    description: 'How we developed an app, designed to explore, try and learn yoga poses',
    images: {
      //   ['308x308']: '/images/portfolio/yoga_prev.png',
      ['380x380']: require('../images/yoga-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/yoga-420-420@2x.jpg').default?.src,
      ['728x728']: require('../images/yoga-728x728@2x.jpg').default?.src,
    },
    slug: 'yoga',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 40,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 30),
    title: 'SmilesOn. Photo albums online booking service',
    description: 'How we digitalized offline business in photo printing industry',
    images: {
      //   ['308x308']: require('../../../assets/images/smileson/smileson-308x308@2x.jpg'),
      ['380x380']: require('../images/smileson-420x420@2x.jpg').default?.src,
      ['420x420']: require('../images/smileson-420x420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'smileson',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 24,
    showInPortfolio: true,
    category: categories.find(({ slug }) => slug === 'wellness'),
    title: 'EasyStyle. Appointment booking mobile app',
    description:
      'How we developed simple and convinient service to find & book appointments for professionals in the beauty industry',
    images: {
      //   ['308x308']: require('../../../assets/images/easystyle/easystyle-308x308@2x.png'),
      ['380x380']: require('../images/easystyle-420x420@2x.jpg').default?.src,
      ['420x420']: require('../images/easystyle-420x420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'easystyle',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 15,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 30),
    title: 'Parking access control system',
    description:
      'How we implemented a tool that allows access parking spots in a few clicks for users, as well as provide and manage parking areas for providers',
    images: {
      //   ['308x308']: '/images/hippo/hippo_parking_preview.jpg',
      ['380x380']: require('../images/hippoparking-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/hippoparking-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'hippo-parking',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 54,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 30),
    title: 'Global Key. Website & personal account for B2B',
    description:
      'How we built a company platform for import and distribution of electronic components',
    images: {
      //   ['308x308']: require('../../../assets/images/global-key/global-key-308x308@2x.png'),
      ['380x380']: require('../images/globalkey-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/globalkey-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'global-key',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 16,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 1),
    title: 'Ronin. Platform for online orders from the restaurant',
    description: 'How we helped a business establish and automate online orders and delivery',
    images: {
      //   ['308x308']: '/images/ronin/ronin_preview.jpg',
      ['380x380']: require('../images/ronin-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/ronin-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'ronin',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 17,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 30),
    title: 'Service to find door installers near you',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/doorsinstall/doorsinstall-308x308@2x.jpg'),
      ['380x380']: require('../images/doorsinstall-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/doorsinstall-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: 'https://doors-install.com',
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 18,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 25),
    title:
      'A portal for interaction between residents of a trendy residential neighborhood and the local management company',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/a100/a100-308x308@2x.jpg'),
      ['380x380']: require('../images/a100-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/a100-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 19,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 15),
    title: 'Service for automating the process of leasing cars',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/atl/atl-308x308@2x.jpg'),
      ['380x380']: require('../images/atl-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/atl-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 22,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 5),
    title: 'The platform for boats and yachts retail',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/cbay/cbay-308x308@2x.jpg'),
      ['380x380']: require('../images/cbay-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/cbay-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: 'https://cbay.io',
      appIos: null,
      appAndroid: null,
    },
  },

  {
    id: 50,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 5),
    title: 'DropsAuto. B2B personal account & online store',
    description:
      'How we implemented a bunch of marketing solutions for the wholesale supplier of auto chemicals',
    images: {
      //   ['308x308']: require('../../../assets/images/drops/drops-308x308@2x.jpg'),
      ['380x380']: require('../images/drops-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/drops-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'drops',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },

  {
    id: 21,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 10),
    title: 'Travel aggregator',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/bluebirdtravel/bluebirdtravel-308x308@2x.jpg'),
      ['380x380']: require('../images/bluebirdtravel-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/bluebirdtravel-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },

  {
    id: 25,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 40),
    title: 'Online specialists certification platform',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/expertrb/expertrb-308x308@2x.jpg'),
      ['380x380']: require('../images/expertrb-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/expertrb-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 26,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 5),
    title: 'Online sporting goods store',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/fizcult/fizcult-308x308@2x.jpg'),
      ['380x380']: require('../images/fizcult-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/fizcult-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 27,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 5),
    title: 'Online photo store',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/getlens/getlens-308x308@2x.jpg'),
      ['380x380']: require('../images/getlens-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/getlens-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 29,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 40),
    title: 'A platform for online language learning with tutors from around the world',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/livexp/livexp-308x308@2x.jpg'),
      ['380x380']: require('../images/livexp-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/livexp-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: 'http://livexp.com',
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 30,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 15),
    title: 'A digital wallet to store usd or crypto',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/metalpay/metalpay-308x308@2x.jpg'),
      ['380x380']: require('../images/metalpay-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/metalpay-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 31,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 20),
    title: 'Website of the channel with online broadcasting',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/mir24/mir24-308x308@2x.jpg'),
      ['380x380']: require('../images/mir24-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/mir24-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 32,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 50),
    title: 'Fundraising platform',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/molamola/molamola-308x308@2x.jpg'),
      ['380x380']: require('../images/molamola-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/molamola-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 33,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 55),
    title: 'Service to send tokens to multiple addresses',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/multisender/multisender-308x308@2x.jpg'),
      ['380x380']: require('../images/multisender-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/multisender-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: 'https://multisender.app',
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 34,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 10),
    title: 'Bus booking app',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/npm/npm-308x308@2x.jpg'),
      ['380x380']: require('../images/npm-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/npm-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 35,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 20),
    title: 'Website of the channel with online broadcasting',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/ont/ont-308x308@2x.jpg'),
      ['380x380']: require('../images/ont-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/ont-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 36,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 20),
    title: 'Online radio station',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/radiomir/radiomir-308x308@2x.jpg'),
      ['380x380']: require('../images/radiomir-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/radiomir-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 38,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 5),
    title: 'Online store of goods for home and leisure',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/realshop/realshop-308x308@2x.jpg'),
      ['380x380']: require('../images/realshop-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/realshop-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 39,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 5),
    title: 'The platform for brand clothes retail',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/showoff/showoff-308x308@2x.jpg'),
      ['380x380']: require('../images/showoff-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/showoff-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },

  {
    id: 41,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 30),
    title: 'Automation of interactions with the dentist',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/smilewell/smilewell-308x308@2x.jpg'),
      ['380x380']: require('../images/smilewell-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/smilewell-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 42,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 20),
    title: 'Online media',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/soyuz/soyuz-308x308@2x.jpg'),
      ['380x380']: require('../images/soyuz-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/soyuz-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 43,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 45),
    title: 'A platform for interaction between bloggers and advertisers',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/tenderbase/tenderbase-308x308@2x.jpg'),
      ['380x380']: require('../images/tenderbase-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/tenderbase-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 44,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 25),
    title: 'Apartment Marketplace',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/terkan/terkan-308x308@2x.jpg'),
      ['380x380']: require('../images/terkan-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/terkan-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 45,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 55),
    title: 'Blockchain loyalty programm',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/toki/toki-308x308@2x.jpg'),
      ['380x380']: require('../images/toki-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/toki-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: 'https://www.buildwithtoki.com',
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 46,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 30),
    title: 'B2B Personal Account for sales Equipment for livestock and poultry',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/topixagro/topixagro-308x308@2x.jpg'),
      ['380x380']: require('../images/topixagro-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/topixagro-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 47,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 30),
    title: 'Service for remote territory access control',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/touchgo/touchgo-308x308@2x.jpg'),
      ['380x380']: require('../images/touchgo-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/touchgo-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 20,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 5),
    title: 'Marketplace for selling clothes',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/belbazar/belbazar-308x308@2x.jpg'),
      ['380x380']: require('../images/belbazar-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/belbazar-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 48,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 10),
    title: 'Travel aggregator',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/trofei/trofei-308x308@2x.jpg'),
      ['380x380']: require('../images/trofei-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/trofei-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 49,
    showInPortfolio: false,
    category: categories.find(({ id }) => id === 25),
    title: 'Real estate website',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/zelgavan/zelgavan-308x308@2x.jpg'),
      ['380x380']: require('../images/zelgavan-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/zelgavan-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },

  {
    id: 51,
    showInPortfolio: false,
    category: null,
    title: 'Online gaming championship app',
    description: 'How we made YPA, food aggregator with automatic payment',
    images: {
      //   ['308x308']: require('../../../assets/images/esn/esn-308x308@2x.jpg'),
      ['380x380']: require('../images/esn-308x308@2x.jpg').default?.src,
      ['420x420']: require('../images/esn-308x308@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: null,
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 52,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 1),
    title: 'TikTak. Food places aggregator',
    description:
      'How we created a food aggregator with automatic payments and table reservation system',
    images: {
      //   ['308x308']: require('../../../assets/images/tiktak/tiktak-og-sq.jpg'),
      ['380x380']: require('../images/tiktak-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/tiktak-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'tiktak',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 53,
    showInPortfolio: true,
    category: categories.find(({ slug }) => slug === 'others'),
    title: 'Zubr Capital. Portfolio website & training platform',
    description:
      'How we designed a clean and modern corporate website and a training platform for a private equity investment company',
    images: {
      //   ['308x308']: require('../../../assets/images/zubr/zubr-og-sq.jpg'),
      ['380x380']: require('../images/zubrcapital-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/zubrcapital-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'zubr',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 4,
    showInPortfolio: true,
    category: categories.find(({ id }) => id === 55),
    title: 'Plasma. Private messenger',
    description:
      'Creation of a blockchain based app for exchanging private messages between different platform users',
    images: {
      //   ['308x308']: '/images/portfolio/plasma-sq.png',
      ['380x380']: require('../images/plasma-420-420@2x.jpg').default?.src,
      ['420x420']: require('../images/plasma-420-420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'plasma',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
  {
    id: 3,
    showInPortfolio: true,
    category: categories.find(({ slug }) => slug === 'others'),
    title: 'Ulej. Crowdfunding platform',
    description:
      'We created a new web platform, that was not just aesthetically attractive, but also self-explanatory and user-friendly',
    images: {
      //   ['308x308']: '/images/portfolio/ulej-sq.png',
      ['380x380']: require('../images/ulej-420x420@2x.jpg').default?.src,
      ['420x420']: require('../images/ulej-420x420@2x.jpg').default?.src,
      ['728x728']: null,
    },
    slug: 'ulej',
    links: {
      site: null,
      appIos: null,
      appAndroid: null,
    },
  },
]
